import * as React from "react";
import { graphql } from "gatsby";
import { IExpressTermsServiceProps } from "../pageHelpers/ExpressTermsService/ExpressTermsServiceProps";
import { Container } from "../pageHelpers/ExpressTermsService/ExpressTermsServiceHelpers";
import Layout from "../components/global/Layout/Layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


const ExpressTermsService = (props: IExpressTermsServiceProps) => {
  const terms = props.data.allNodePage.edges.length ? props.data.allNodePage.edges[0].node : null;
  return (<Layout>
    <Container>
      <Row>
        <Col>
          {terms !== null &&
            <>
              <h1>{terms.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: terms.body.processed }} />
            </>
          }
        </Col>
      </Row>
    </Container>
  </Layout>);
};
export const pageQuery = graphql`
  query ExpressTermsServiceQuery {
    allNodePage(filter: {path: {alias: {eq: "/express-terms-service"}}}) {
      edges {
        node {
          title
          path {
            alias
          }
          body {
            processed
            value
          }
        }
      }
    }
  }
`;
export default ExpressTermsService;